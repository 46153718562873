import * as api from './http'

/* banner 轮播 */
export function get_address(params){
    return api.postApi('Home/get_address', params)
}

/* 勋章列表 */
export function medal_list(params){
    return api.postApi('Home/medal_list', params)
}

/* 协议 */
export function commonPrivacy(params){
    return api.postApi('/Home/privacy', params)
}

/* 判断机构信息是否完善 */
export function organ_perfect(params){
    return api.postApi('Organ/organ_perfect', params)
}

/* 所有课程 */
export function all_course(params){
    return api.postApi('Home/all_course', params)
}

/* 讲师详情 */
export function lecturer_info(params){
    return api.postApi('Home/lecturer_info', params)
}

/* 生成二维码 */
export function scerweima(params){
    return api.postApi('Organ/scerweima', params)
}

/* 合作伙伴列表 */
export function partners_list(params){
    return api.postApi('Home/partners_list', params)
}

/* 判断是否有权限观看 */
export function istoinfo(params){
    return api.postApi('Home/istoinfo', params)
}

/* 微信QQ登录 */
export function get_user_unique(params){
    return api.postApi('Login/get_user_unique', params)
}

/* 三方登录绑定手机号 */
export function tripartite_register(params){
    return api.postApi('Login/tripartite_register', params)
}

/* 获取pc端微信登陆唯一标识 */
export function pc_wxlogin_openid(params){
    return api.postApi('Login/pc_wxlogin_openid', params)
}

/* 购物车角标 */
export function goods_count(params){
    return api.postApi('Goods/goods_count', params)
}