export default {
  namespaced: true,
  state: {
    uploadBack: []
  },
  mutations: {
    SET_UPLOADBACK (state, value) {
      state.uploadBack = value
    }
  },
  actions: {
  },
}