export default {
  user_num: state => state.user.userNum,
  token: state => state.user.token,
  navIndex: state => state.navlist.nowIndex,
  Baseurl: state => state.common.Baseurl,
  requestBaseUrl: state => state.common.requestBaseUrl,
  personal: state => state.user.personal,
  cominfor: state => state.user.cominfor,
  sign_dialogVisible: state => state.user.sign_dialogVisible,
  SignInfo: state => state.user.SignInfo,
  addressList: state => state.user.addressList,
  accountShop: state => state.common.accountShop,
  step: state => state.common.step,
  shopOrder: state => state.common.shopOrder,
  addAddressHidden: state => state.user.addAddressHidden,
  addressBackData: state => state.user.addressBackData,
  setaddressType: state => state.user.setaddressType,
  navList: state => state.common.navlist,
  wechatInfo: state => state.common.wechatInfo,
  goodsCound: state => state.common.goodsCound,
  uploadBack: state => state.meeting.uploadBack,
}