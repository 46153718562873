import personalChildren from './children/index'
export default [
    {
        path: '/personal',
        name: 'personal',
        redirect: '/personal/myinfo',
        component: () => import('@/views/layout/myCenter/index'),
        meta: {
            title: '个人中心'
        },
        children:[
            ...personalChildren
        ]
    },
]