import Vue from 'vue'
import VueRouter from 'vue-router'
import example from './otherRouter/example'
import do_login from '@/router/otherRouter/do_login'
import webMain from '@/router/otherRouter/webMain'
import myCenter from '@/router/myCenter/index'
import play from '@/router/play/index'
import studyplan from '@/router/studyPlan'
import shop from '@/router/shop'
import meeting from './meeting/index.js'


/* 

  引入方式
    推荐使用懒加载

  懒加载
  component: () => import('@/views/About.vue')

  预加载
  import Home from '@/views/Home.vue'

  路由守卫在最外层的 permission.js 中

  {
    path: '/',
    name: 'Home',         name  必填，keep-live 用
    component: Home,
    meta:{
      title: "首页"       title 必填，设置页面标题用
    }
  },

*/

/* 解决重复点击同一地址栏报错 */
const includPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return includPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'webMain',
    component: () => import('@/views/webMain.vue'),
    meta: {
      title: "首页"
    },
  },
  // {
  //   path: '/pages/players/video_brief',
  //   name: 'webMain',
  //   component: () => import('@/views/handleTemp.vue'),
  //   meta:{
  //     title: "首页"
  //   },
  // },
  {
    path: '/bindMobile',
    name: 'bindMobile',
    component: () => import('@/views/layout/bindMobile.vue'),
    meta: {
      title: '绑定手机号'
    },
  },
  {
    path: '/bannerdetail',
    name: 'bannerDetail',
    component: () => import('@/views/bannerDetail.vue'),
    meta: {
      title: '详情'
    },
  },
  {
    path: '/advertdetail',
    name: 'advertdetail',
    component: () => import('@/views/advertDetail.vue'),
    meta: {
      title: '详情'
    },
  },
  ...example,
  ...do_login,
  ...webMain,
  ...myCenter,
  ...play,
  ...studyplan,
  ...shop,
  ...meeting
]

const router = new VueRouter({
  mode: 'hash',
  // base: '/pc/',
  routes
})

export default router
