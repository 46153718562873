import Vue from 'vue'
import App from './App.vue'

const u = navigator.userAgent;
const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端 
const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端 
const url = window.location.href.substring(window.location.href.indexOf('/#/') + 2)
if (isAndroid || isiOS) {//android终端 || ios终端
  if (!url.startsWith('/pages')) {
    window.location.href = 'http://nmep.com.cn/#/' 
    // var href = 'http://h5test.test01.qcw100.com/'
    // window.location.href = href
  }
} else {
  if (url.startsWith('/pages')) {
    // window.location.href = 'http://nmep.com.cn/#/'、 h5 跳 pc http://pctest.test01.qcw100.com/#/
    // var href = 'http://pctest.test01.qcw100.com/'
    var href = 'http://nmep.com.cn'
    if (url.startsWith('/pages/players/video_brief') && getQueryVariable().course_id) {
      window.location.href = href + '/#/play?id=' + getQueryVariable().course_id
    } else if (url.startsWith('/pages/players/video_h5') && getQueryVariable().course_id && getQueryVariable().setvid) {
      window.location.href = href + `/#/play/noDemand/${getQueryVariable().course_id}/${getQueryVariable().setvid}`
    } else if (url.startsWith('/pages/meeting/meetingDetail') && getQueryVariable().id) {
      window.location.href = href + `/#/meetingDetail?meetingId=${getQueryVariable().id}`
    } else {
      window.location.href = href
    }

  }
  // 
  // ('当前是其他终端')
  // window.location.href
}
function getQueryVariable () {
  if (url.split('?').length == 0) return {}
  var option = url.split('?')[1]
  var params = {}
  option.split('&').forEach(v => {
    var obj = {}
    params[v.split('=')[0]] = v.split('=')[1]
  })
  return params
}

import './registerServiceWorker'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/element-variables.scss'

import './permission'

/* css */
import './assets/css/common.css'
import './assets/css/iconfont/iconfont.css'



Vue.use(ElementUI)


import global from './utils/global.js'
Vue.prototype.$global = global;
Vue.prototype.$store = store

// 用于及时更新接口
Vue.prototype.$editUploadBack = function (type, modular) {  //增 删 查 
  var uploadBack = store.getters.uploadBack
  store.commit('meeting/SET_NAVLIST', [])
  if (type == 1) {
    if (uploadBack.indexOf(modular) == -1) {
      uploadBack.push(modular)
      store.commit('meeting/SET_NAVLIST', uploadBack)
    }
  } else if (type == 2) {
    if (uploadBack.indexOf(modular) != -1) {
      uploadBack.splice(uploadBack.indexOf(modular), 1)
      store.commit('meeting/SET_NAVLIST', uploadBack)
    }
  } else if (type == 3) {
    var flag = uploadBack.indexOf(modular) != -1 ? true : false
    if (flag) {
      uploadBack.splice(uploadBack.indexOf(modular), 1)
      store.commit('meeting/SET_NAVLIST', uploadBack)
    }
    return flag
  }

}



Vue.prototype.goother = (params) => {
  // console.log(params)
  // console.log(88899988888999000)
  router.push(params)
}
Vue.prototype.scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
