export default {
    namespaced: true,
    state: {
        nowIndex:0
    },
    mutations: {
        SET_NOWINDEX(state, value){
            state.nowIndex = value
        }
    },
    actions: {
        set_nowIndex({ commit  }, value){
            commit('SET_NOWINDEX',value)
        }
    },
}