import * as api from './http'

/* 购物车列表 */
export function get_goods_car(params){
    return api.postApi('goods/get_goods_car', params)
}

/* 修改购物车商品数量 */
export function edit_goods_car(params){
    return api.postApi('goods/edit_goods_car', params)
}

/* 删除购物车商品 */
export function del_goods_car(params){
    return api.postApi('goods/del_goods_car', params)
}

/* 收货地址列表 */
export function address_list(params){
    return api.postApi('goods/address_list', params)
}

/* 添加地址 */
export function add_address(params){
    return api.postApi('goods/add_address_id', params)
}

/* 购物车订单详情 */
export function goods_car_detail(params){
    return api.postApi('goods/goods_car_detail', params)
}

/* 购物车创建订单 */
export function create_pccar_order(params){
    return api.postApi('Goods/create_pccar_order', params)
}

/* pc端支付 */
export function goods_order_pc(params){
    return api.postApi('Goods/goods_order_pc', params)
}

/* 编辑地址 */
export function edit_address(params){
    return api.postApi('goods/edit_address_id', params)
}

/* 回显地址 */
export function address_detail_id(params){
    return api.postApi('goods/address_detail_id', params)
}

/* 删除地址 */
export function del_address(params){
    return api.postApi('goods/del_address', params)
}

/* 商品详情立即下单--创建订单 */
export function create_order_pc(params){
    return api.postApi('Goods/create_order_pc', params)
}