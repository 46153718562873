import * as api from './http'

/* banner 轮播 */
export function banner(params){
    return api.postApi('Home/banner', params)
}

/* 分类列表 */
export function type_list(params){
    return api.postApi('Home/type_list', params)
}

/* 讲师列表 */
export function lecturer_list(params){
    return api.postApi('Home/lecturer_list', params)
}

/* 热门课程 */
export function hot_course(params){
    return api.postApi('Home/hot_course', params)
}

/* 首页课程分类 */
export function home_type_list(params){
    return api.postApi('Home/home_type_list', params)
}

/* 根据课程分类查询课程 */
export function course_by_type(params){
    return api.postApi('Home/course_by_type', params)
}

/* 根据首页分类查询 */
export function course_home_type(params){
    return api.postApi('Home/course_home_type', params)
}

/* 广告位 */
export function advert_list(params){
    return api.postApi('Home/advert_list', params)
}

/* 课程分类 */
export function course_type(params){
    return api.postApi('Home/course_type', params)
}

/* 课程分类 */
export function listinfo(params){
    return api.postApi('Home/listinfo', params)
}

/* 搜索课程 */
export function course_search(params){
    return api.postApi('Home/course_search', params)
}