export default [
    {
        path: '/play',
        // path: '/play/:id',
        name: 'play',
        component: () => import('@/views/layout/play/index'),
        meta: {
            title: '课程详情',
            keepAlive: false
        },
    },
    {   
        // 第二个
        path: '/play/noDemand/:id/:vid',
        // 第一个
        // path: '/play/noDemand',
        name: 'noDemand',
        component: () => import('@/views/layout/play/noDemand'),
        meta: {
            title: '课程点播'
        },
    },
    {   
        path: '/play/livePlay',
        name: 'livePlay',
        component: () => import('@/views/layout/play/livePlay'),
        meta: {
            title: '课程直播'
        },
    },
    {   
        path: '/play/richText',
        name: 'richText',
        component: () => import('@/views/layout/play/richText'),
        meta: {
            title: '课程广告详情'
        },
    }
]