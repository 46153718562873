import axios from 'axios';
import qs from 'qs'
import store from '../../store'
import global from '../global.js'

import { Message } from 'element-ui';
let baseURL = 'http://nmep.com.cn/api.php/api/'; //  定义基本url
// let baseURL = 'http://xinyiyun.test01.qcw100.com:8000/api/'
axios.defaults.baseURL = global.baseUrl
// axios.defaults.headers.post['Content-Type'] = 'application/json;charSet=UTF-8'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

axios.defaults.headers.get['Content-Type'] = 'application/json;charSet=UTF-8'

// 添加请求拦截器
/* axios.interceptors.request.use((config) => {
  //   localStorage.getItem('token')
  let Token = store.getters['user/getUserInfo'].token;
  if (Token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
    config.headers.Authorization = `Bearer ${Token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
}) */
const whiteURL = ['http://api.polyv.net/live/v3/channel/common/get-chat-token']
const whiteURLParams = ['Home/istoinfo', 'Organ/hand_task', 'Organ/organ_perfect', 'Organ/branch_hand_task']
// 添加响应拦截器
axios.interceptors.response.use((response) => {
  if (response.status !== 200) {
    Message.error('哎呀，数据走丢啦！')
    return Promise.reject(new Error(response.data.msg || 'Error'))
  }
  let getAddress = response.config.url.split('?')[0] == 'http://restapi.amap.com/v3/ip'
  if (whiteURL.includes(response.config.url) || whiteURLParams.includes(response.config.url)) {
    return response.data
  }
  if (response.config.url == 'Meet/ticketPay' && response.config && response.config.data && response.config.data.split('&').find(h => h.split('=')[0] == 'pay_type') && response.config.data.split('&').find(h => h.split('=')[0] == 'pay_type').split('=')[1] == 1) {
    return response.data
  }
  if (response.config.url == 'Meet/payRoom' && response.config && response.config.data && response.config.data.split('&').find(h => h.split('=')[0] == 'type') && response.config.data.split('&').find(h => h.split('=')[0] == 'type').split('=')[1] == 1) {
    return response.data
  }
  if (response.config.url == 'Goods/goods_order_pc') {
    return response.data
  }
  if (getAddress) {
    return response.data
  }
  let count
  if (response.data.count) {
    count = response.data.count
  }
  const res = response.data.data
  if (response.status == null) {
    console.warn(res);
    return;
  }

  if (response.data.status == 1) {
    if (count) {
      return {
        res, count
      }
    }
    return res
  }
  if (response.data.status == 3) {
    Message.error('操作失败：' + response.data.msg)
    return
  }
  if (response.data.status == 0) {
    Message.error(response.data.msg)
    return
  }
  if (response.data.status == 666) {
    // Message.warning('未登录账号')
    return
  }
  if (response.data.status == 6666) {
    // Message.warning('账号异地登录，即将返回登录页面')
    return
  }
},
  (error) => {
    return Promise.reject(error)
  }
)

// 封装的方法

/**

 * get 方法封装

 * @param url

 * @param params

 * @returns {Promise}

 */

export function getApi (url, params = {}) {
  params.token = store.getters.token
  return new Promise((resolve, reject) => {
    axios.get(`${url}`, {
      params: params || {}
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error);
    });
  })
}

/**

 * post

 * @param url

 * @param params

 * @returns {Promise}

 */

export function postApi (url, params = {}) {
  params.token = store.getters.token
  return new Promise((resolve, reject) => {
    axios.post(`${url}`, qs.stringify(params))
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      })
  })
}