import { setToken } from '@/utils/auth'
import { sign } from '@/utils/Api/userList'
import { get_user } from '@/utils/Api/userList'
import { listinfo } from '@/utils/Api/homeList'
import { address_list, add_address, address_detail_id, edit_address } from '@/utils/Api/shop'
import { Message, MessageBox } from 'element-ui';
import router from '../../router'
export default {
  namespaced: true,
  state: {
    userNum: '1113',
    token: '',
    personal: {},
    cominfor: {},
    SignInfo: {},
    sign_dialogVisible: false,
    addressList: [],
    addAddressHidden: false,
    addressBackData: {},
    setaddressType: 1
  },
  mutations: {
    SET_SETADDRESSTYPE (state, value) {
      state.setaddressType = value
    },
    SET_TOKEN (state, value) {
      setToken(value)
      state.token = value
    },
    SET_USERINFO (state, value) {
      state.personal = value
    },
    SET_COMINFOR (state, value) {
      state.cominfor = value
    },
    SET_SIGNINFO (state, value) {
      state.SignInfo = value
    },
    SET_SIGNHIDDEN (state, value) {
      state.sign_dialogVisible = value
    },
    SET_ADDRESSLIST (state, value) {
      state.addressList = value
    },
    SET_ADDRESSHIDDEN (state, value) {
      state.addAddressHidden = value
    },
    SET_ADDRESSBACKDATA (state, value) {
      state.addressBackData = value
    }
  },
  actions: {
    loginRedirect ({ }, value) {
      MessageBox.confirm(value, {
        cancelButtonText: '知道了',
        confirmButtonText: '去登陆',
        type: 'warning'
      }).then(() => {
        router.push({ path: '/login', query: { redirect: encodeURI(router.app._route.fullPath) } })
      }).catch(() => { })
    },
    addressDetailId ({ commit }, value) {
      address_detail_id({ id: value }).then(res => {
        commit('SET_ADDRESSBACKDATA', res)
        commit('SET_ADDRESSHIDDEN', true)
      })
    },
    user_show () {
    },
    set_token ({ commit }, value) {
      commit('SET_TOKEN', value)
    },
    async getUserInfor ({ commit }) {
      let userinfo = await get_user()
      if (userinfo) {
        commit('SET_USERINFO', userinfo)
      }
    },
    async myInfor ({ commit }) {
      let cominfor = await listinfo()
      if (cominfor) {
        commit('SET_COMINFOR', cominfor)
      }
    },
    logOut ({ commit }) {
      commit('SET_USERINFO', {})
      commit('SET_TOKEN', '')
    },
    async mysign ({ commit, dispatch, state }) {
      if (!state.token) {
        dispatch('loginRedirect', '未登录无法签到哦~')
        return
      }
      let res = await sign()
      if (res) {
        commit('SET_SIGNINFO', res)
        dispatch('getUserInfor')
        commit('SET_SIGNHIDDEN', true)
        return
      }
    },
    async address ({ commit }) {
      let res = await address_list()
      if (res) {
        commit('SET_ADDRESSLIST', res)
        return
      }
    },
    async addAddress ({ commit, state }, value) {
      // console.log(value)
      let params = {
        ...value,
        is_default: value.is_default ? 1 : 0
      }
      if (!params.consignee_name) {
        Message.warning('请填写收货人！')
        return
      }
      if (!params.consignee_phone) {
        Message.warning('请填写手机号！')
        return
      }
      if (!params.provice || !params.city || !params.area) {
        Message.warning('请选择地址！')
        return
      }
      if (!params.address) {
        Message.warning('请填写详细地址！')
        return
      }
      /* state.setaddressType   1 是新增地址 2 是修改地址 */
      // console.log(state.setaddressType)
      let res, text
      if (state.setaddressType + '' == '1') {
        res = await add_address(params)
        text = '地址添加成功！'
      }
      if (state.setaddressType + '' == '2') {
        res = await edit_address(params)
        text = '地址编辑成功！'
      }
      if (res.length < 1) {
        commit('SET_ADDRESSHIDDEN', false)
        let res = await address_list()
        if (res) {
          commit('SET_ADDRESSLIST', res)
          return
        }
        Message.success(text)
      }
    },
    async addAddress1 ({ commit, state }, value, callback) {
      let params = {
        ...value,
        is_default: value.is_default ? 1 : 0
      }
      let res, text
      if (state.setaddressType + '' == '1') {
        res = await add_address(params)
        text = '地址添加成功！'
      }
      if (state.setaddressType + '' == '2') {
        res = await edit_address(params)
        text = '地址编辑成功！'
      }
      if (res.length < 1) {
        commit('SET_ADDRESSHIDDEN', false)
        let res = await address_list()
        if (res) {
          commit('SET_ADDRESSLIST', res)
          return
        }
        Message.success(text)
      }
    }
  },
}