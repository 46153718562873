export default [
  {
    path: '/meetingDetail',
    name: 'meetingDetail',
    component: () => import('@/views/layout/meeting/meetingDetail'),
    meta: {
      title: '活动详情'
    },
  },
  {
    path: '/hotelReservation',
    name: 'hotelReservation',
    component: () => import('@/views/layout/meeting/hotelReservation'),
    meta: {
      title: '酒店列表'
    }
  },
  {
    path: '/hotelDetail',
    name: 'hotelDetail',
    component: () => import('@/views/layout/meeting/hotelDetail'),
    meta: {
      title: '酒店详情'
    }
  },
  {
    path: '/lineBooking',
    name: 'lineBooking',
    component: () => import('@/views/layout/meeting/lineBooking'),
    meta: {
      title: '在线预定'
    }
  },
  {
    path: '/registrationFilling',
    name: 'registrationFilling',
    component: () => import('@/views/layout/meeting/registrationFilling'),
    meta: {
      title: '一键报名'
    }
  },
  {
    path: '/onlinePayment',
    name: 'onlinePayment',
    component: () => import('@/views/layout/meeting/onlinePayment'),
    meta: {
      title: '在线支付'
    }
  },
]