import { goods_count } from '@/utils/Api/commonList'
export default {
  namespaced: true,
  state: {
    Baseurl: "http://nmep.com.cn/",
    requestBaseUrl: "http://nmep.com.cn/api.php/api/",
    // Baseurl: "http://xinyiyun.test01.qcw100.com/",
    // requestBaseUrl: "http://xinyiyun.test01.qcw100.com/api/",
    accountShop: [],
    step: 1,
    shopOrder: [],               // 商品详情立即下单携带参数
    navlist: '',                 // 面包屑
    wechatInfo: {},              // 微信信息
    goodsCound: 0,              // 购物车角标数量
  },
  mutations: {
    SET_ACCOUNTSHOP (state, value) {
      state.accountShop = value
    },
    SET_STEP (state, value) {
      state.step = value
    },
    SET_SHOPORDER (state, value) {
      state.shopOrder = value
    },
    SET_NAVLIST (state, value) {
      state.navlist = value
    },
    wxInfo (state, value) {
      state.wechatInfo = value
    },
    SET_GOODSCOUNT (state, value) {
      state.goodsCound = value
    }
  },
  actions: {
    getCount ({ commit }) {
      goods_count().then(res => {
        commit('SET_GOODSCOUNT', res)
      })
    }
  },
}