<template>
  <div id="app">
    <!-- 顶部导航条 Start -->
    <header>
      <div class="headerCenter centerWidth marginAuto flex spacebetween alignCenter">
        <span class="cGray welcomeText"
              @click="goother({path: '/'})">欢迎访问新医云学苑官网</span>
        <ul>
          <li class=" flex spacearound alignCenter cGoodsCar goodsBtn">
            <div class=" flex alignCenter"
                 @click="gogoods"
                 style="position: relative;">
              <img src="@/assets/img/header/goodsCar.png"
                   class="headerIcon"
                   alt=""
                   srcset="">
              购物车
              <div v-show="$store.getters.token && goodsCound > 0"
                   class="carCount">{{goodsCound}}</div>
            </div>
          </li>
          <!-- <li v-if="!userInfo.id" class="c484343 flex spacearound alignCenter" ><span style="border-right:1px solid #707070;padding-right:32px;padding-left:32px;" @click="goother({path:'/login',query:{userType: 1}})">个人登录</span></li>
          <li v-if="!userInfo.id" class="c66 flex spacearound alignCenter" ><span style="padding-right:32px;padding-left:32px;" @click="goother({path:'/login',query:{userType: 2}})">机构登录</span></li> -->
          <li v-if="!userInfo.id"
              class="c66 flex spacearound alignCenter"><span style="padding-right:32px;padding-left:32px;"
                  @click="goother({path:'/login'})">去登录</span></li>
          <li v-if="userInfo.id"
              class="flex alignCenter c484343 useroper spacebetween"
              style="font-size:14px;padding-right:50px;">
            <div class="flex alignCenter fwtHidden"><img :src="userInfo.portrait"
                   alt=""
                   style="width:28px;height:28px;border-radius:50px;margin-left:20px;margin-right:15px;">
              <div style="max-width:160px;"
                   class="fwtHidden">
                {{userInfo.capacity == 3 ? userInfo.hospital.full_name ? userInfo.hospital.full_name :'新医云用户' : userInfo.real_name}}
              </div>
            </div>
            <i class="el-icon-arrow-down"
               style="float:right;"></i>
            <div class="oper">
              <div @click="goother({path:'/personal'})"
                   class="logout flex alignCenter spacearound">个人中心</div>
              <div @click="logout"
                   class="logout flex alignCenter spacearound"
                   style="border-top:1px solid #EDEFF4">退出登录</div>
            </div>
          </li>
        </ul>
      </div>
    </header>
    <!-- 顶部导航条 End -->
    <!-- 导航条 Start -->
    <nav class="centerWidth marginAuto flex spacebetween alignCenter">
      <div class="leftContent flex alignCenter">
        <img src="@/assets/img/logoWeb.png"
             style="cursor: pointer;height: 38px;"
             @click="goother({path: '/'})"
             class="logoWeb"
             alt="">
        <!-- 未登录-->
        <!-- <div v-show="!$store.getters.token" class="welcomeTo">欢迎登录新医云学苑</div> -->

      </div>
      <div class="centerContent">
        <li class="navList"
            v-for="(im,ix) in App_navlist"
            :key="ix"
            :class="getCurr(ix)"
            @click="$store.dispatch('navlist/set_nowIndex', ix);goother({path: im.path});"
            @mouseenter="App_tempIndex = ix"
            @mouseleave="App_tempIndex = null">
          {{im.title}}
        </li>
      </div>
      <!--  v-show="$store.getters.token" -->
      <div class="rightContent flx">
        <div class="searchContent flex spacebetween alignCenter">
          <input type="text"
                 v-model="searchwords"
                 :placeholder="searchPlace"
                 @keydown.enter="searchEntry" />
          <div class="searchContent_btn flex spacearound alignCenter"
               @click="searchEntry"><img src="@/assets/img/header/searchIcon.png"
                 alt=""></div>
        </div>
        <div class="signBtn cursorP"
             @click="$store.dispatch('user/mysign')">
          <img src="@/assets/img/header/signIcon.png"
               alt="">
          <span>签到</span>
        </div>
        <!--  style="width:53px;height:53px;" -->
        <div class="signBtn calcIMg">
          <!-- <img src="@/assets/img/bottomImg/smxzapp.png"
               alt=""> -->
          <img src="@/assets/img/bottomImg/smxzapp2023.png"
               alt="">
          <span class=downapp></span>
          <div class="BigImgShow calcIMgShow">
            手机扫码下载
            <img class="calcIMgShowImg"
                 src="@/assets/img/bottomImg/smxzapp2023.png"
                 alt="">
            <!-- <img class="calcIMgShowImg"
                 src="@/assets/img/bottomImg/smxzapp.png"
                 alt=""> -->
            <!-- <img class="calcIMgShowImg"
                 src="@/assets/img/bottomImg/smxzapp2022.png"
                 alt=""> -->
          </div>
        </div>
      </div>
    </nav>
    <!-- 导航条 End -->
    <router-view class="router_view" />
    <!-- 底部 Start -->
    <footer>
      <div class="centerWidth marginAuto">
        <div class="footerTopContent flex spacebetween ">
          <div class="footerTopContentLeft">
            <img src="@/assets/img/footer/opacityLogo.png"
                 class="opacityLogo"
                 alt="">
            <div class="work flex spacebetween alignCenter flexColumn">
              <div class="worktop flex spacebetween"
                   style="width:100%;">
                <div class="worktopleft flex spacebetween flexColumn">
                  <span class="worktoplefttexttop">商务合作</span>
                  <span class="worktoplefttextbot">胡凤娇 13511039921</span>
                </div>
                <div class="worktopleft flex spacebetween flexColumn">
                  <span class="worktoplefttexttop">联系我们</span>
                  <span class="worktoplefttextbot">张晶超 13436979719</span>
                </div>
              </div>
              <div class="jumpbottom">
                <li v-for="(im,ix) in App_bottomnavlist"
                    :key="ix"
                    @click="goother({path: im.path})">
                  {{im.title}}
                </li>
              </div>
            </div>
          </div>
          <div class="footerTopContentRight">
            <div class="dl">
              <dt>
                <img src="@/assets/img/bottomImg/xyyxy.jpg"
                     style="width:50px;height:50px;"
                     alt="">
              </dt>
              <div class="dd"><span>新医云学苑</span><span>公众号</span></div>
            </div>
            <div class="dl">
              <dt>
                <img src="@/assets/img/bottomImg/xinyijie.jpg"
                     style="width:50px;height:50px;"
                     alt="">
              </dt>
              <div class="dd"><span>新医界</span><span>公众号</span></div>
            </div>
            <div class="dl">
              <dt>
                <img src="@/assets/img/bottomImg/xyyxyM.png"
                     style="width:50px;height:50px;"
                     alt="">
              </dt>
              <div class="dd"><span>新医云学苑</span><span>移动端</span></div>
            </div>
            <div class="dl">
              <dt>
                <!-- <img src="@/assets/img/bottomImg/smxzapp.png"
                     style="width:50px;height:50px;"
                     alt=""> -->
                <img src="@/assets/img/bottomImg/smxzapp2023.png"
                     style="width:50px;height:50px;"
                     alt="">
              </dt>
              <div class="dd"><span>App下载</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="fenge">
        北京新医众成健康科技有限公司 版权所有丨 电商经营许可证 丨 备案许可号为：<a target="_blank" href="https://beian.miit.gov.cn">京ICP备20010957号-1</a>
      </div>
    </footer>
    <!-- 底部 End -->
    <!-- 返回顶部 -->
    <!-- 弹出签到 -->
    <el-dialog title=""
               :visible.sync="sign_dialogVisible"
               :show-close="false"
               :close-on-click-modal="false"
               custom-class="signOut">
      <div class="signBox">
        <div class="signState fontW">{{SignInfo.desc}}</div>
        <div class="signPoint flex spacearound">
          <div class="flex"
               style="align-items: flex-end;"><img src="@/assets/img/header/signPoint.png"
                 style="width:20px;height:20px;"
                 alt="">
            + {{SignInfo.point}} 积分</div>
        </div>
        <div class="flex flexWrap spacebetween marginAuto"
             style="margin-top:13px;width:315px;">
          <img class="otherImg"
               style="width:62px;height:62px;border-radius:6px;margin-left:8px;margin-right:8px;margin-top:12px;"
               v-for="(im, ix) in list"
               :key="ix"
               :src="showImg(im,ix)"
               alt="">
        </div>
        <div class="signDay"
             style="font-size:16px;color:#555555;margin-top:13px;">
          已连续签到 <span style="font-size:24px;color:#1DCAD3;">{{SignInfo.continuation_day}}</span> 天
        </div>
        <div class="signReamk">连续签到奖励更多哦~</div>
        <button class="myRead cursorP"
                @click="$store.commit('user/SET_SIGNHIDDEN', false)">知道了</button>
      </div>
    </el-dialog>
    <!-- 增加地址S -->
    <el-dialog :visible.sync="addAddressHidden"
               :show-close="false"
               :close-on-click-modal="false"
               custom-class="addAddress">
      <div class="addressBox"
           style="margin-top:-40px;">
        <my-add-address ref="myaddaddress"></my-add-address>
        <div class="operBtn">
          <button class="back"
                  @click="$store.commit('user/SET_ADDRESSHIDDEN', false)">返回</button>
          <button class="surenext"
                  @click="addressNext">确定</button>
        </div>
      </div>
    </el-dialog>
    <!-- 增加地址E -->

    <!-- <el-dialog
    :visible.sync="addAddressHidden"
    :show-close="false"
    :close-on-click-modal="false"
    custom-class="addAddress">
    <div class="addressBox" style="margin-top:-40px;">
        <my-add-address ref="myaddaddress"></my-add-address>
        <div class="operBtn">
            <button class="back" @click="$store.commit('user/SET_ADDRESSHIDDEN', false)">返回</button>
            <button class="surenext" @click="$store.dispatch('user/addAddress', $refs.myaddaddress.add_address);$refs.myaddaddress.add_address={}">确定</button>
        </div>
    </div>
    </el-dialog> -->
  </div>
</template>

<script>
import myAddAddress from '@/components/my_addAddress'
import { setToken } from '@/utils/auth'
export default {
  data () {
    return {
      urlShop: ['/shop', '/shopdetail', '/shoplist'],
      searchPlace: '搜索课程标题及讲师',
      searchwords: '',             // 搜索关键字
      list: [
        [
          require('@/assets/img/header/signIn_list1.png'),
          require('@/assets/img/header/sign1.png'),
        ],
        [
          require('@/assets/img/header/signIn_list2.png'),
          require('@/assets/img/header/sign2.png'),
        ],
        [
          require('@/assets/img/header/signIn_list3.png'),
          require('@/assets/img/header/sign3.png'),
        ],
        [
          require('@/assets/img/header/signIn_list4.png'),
          require('@/assets/img/header/sign4.png'),
        ],
        [
          require('@/assets/img/header/signIn_list5.png'),
          require('@/assets/img/header/sign5.png'),
        ],
        [
          require('@/assets/img/header/signIn_list6.png'),
          require('@/assets/img/header/sign6.png'),
        ],
        [
          require('@/assets/img/header/signIn_list7.png'),
          require('@/assets/img/header/sign7.png'),
        ]
      ],
      dialogVisible: false,
      App_navIndex: ['首页', '全部课程', '学习计划', '商城', '关于我们'],
      App_navlist: [
        {
          title: '首页',
          path: '/'
        },
        {
          title: '全部课程',
          path: '/allcourse'
        },
        {
          title: '学习计划',
          path: '/studyplan'
        },
        {
          title: '商城',
          path: '/shop'
        },
        {
          title: '关于我们',
          path: '/personal/aboutours'
        }
      ],
      App_tempIndex: null,
      App_bottomnavlist: [
        {
          title: '帮助中心',
          path: '/personal/helpercenter'
        },
        {
          title: '关于我们',
          path: '/personal/aboutours'
        },
        {
          title: '全部课程',
          path: '/allcourse'
        },
        {
          title: '商城',
          path: '/shop'
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch('common/getCount')
    // this.example()
  },
  methods: {
    searchEntry () {
      // let urlShop = ['/shop', '/shopdetail', '/shoplist']
      if (this.urlShop.includes(this.$route.path)) {
        this.goother({ path: '/shoplist', query: { type: 'search', q: this.searchwords } }); this.searchwords = ''
        return
      }

      this.goother({ path: '/More', query: { type: 'search', words: this.searchwords } }); this.searchwords = ''
    },
    gogoods () {
      if (!this.$store.getters.token) {
        this.$message.warning('账号未登录！')
        return
      }
      this.goother({ path: '/goodslist', query: { s: 1 } })
    },
    addressNext () {
      let params = this.$refs.myaddaddress.add_address
      if (!params.consignee_name) {
        this.$message.warning('请填写姓名')
        return
      }
      if (!params.consignee_phone) {
        this.$message.warning('请填写联系电话')
        return
      }
      if (!(/^[1]([3-9])[0-9]{9}$/).test(params.consignee_phone)) {
        this.$message.warning('联系电话格式不正确')
        return
      }

      if (!params.provice || !params.city || !params.area || !params.address) {
        this.$message.warning('请完整填写收货地址')
        return
      }
      this.$refs.myaddaddress.add_address = {}
      this.$store.dispatch('user/addAddress1', params)
    },
    clearmyaddaddress () {
      // console.log(12312312312)
    },
    showImg (im, ix) {
      if (this.SignInfo.continuation_img > ix) {
        return im[1]
      }
      return im[0]
    },
    getCurr (ix) {
      if (this.App_tempIndex == ix || this.$store.getters.navIndex == ix) {
        return 'bottomBorder'
      }
      return ''
    },
    logout () {
      this.$confirm('确认退出登录', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('user/logOut')
        this.$store.commit('common/SET_GOODSCOUNT', 0)
        setTimeout(() => {
          this.goother({ path: '/' })
        }, 500);
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消退出'
        });
      });
    }
  },
  computed: {
    userInfo () {
      return this.$store.getters.personal
    },
    SignInfo () {
      return this.$store.getters.SignInfo
    },
    sign_dialogVisible () {
      return this.$store.getters.sign_dialogVisible
    },
    addAddressHidden () {
      return this.$store.getters.addAddressHidden
    },
    goodsCound () {
      return this.$store.getters.goodsCound
    }
  },
  watch: {
    $route (to, from) {
      // console.log(this.$route)
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
      const navIndex = this.App_navIndex.indexOf(this.$route.meta.title)
      this.$store.dispatch('navlist/set_nowIndex', navIndex)
      if (this.$route.query.s) {
        this.$store.commit('common/SET_STEP', this.$route.query.s)
      }
      if (this.urlShop.includes(this.$route.path)) {
        this.searchPlace = '搜好物…'
      } else {
        this.searchPlace = '搜索课程标题及讲师'
      }

      if (this.$route.path == '/pages/players/video_brief') {
        this.$router.replace({ path: '/play', query: { id: this.$route.query.course_id } })
      }
    }
  },
  components: {
    myAddAddress
  }
}
</script>

<style>
.plv-live-player-bar-progress-play {
  position: absolute !important;
  background-color: #03a9f4 !important;
  left: 0;
}
.plv-live-player-bar-progress-dot {
  display: none;
}
.pStyle {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  position: absolute;
  right: -7px;
  margin-left: -6px;
  margin-top: -4px;
  background: #fff;
  /* opacity: 0.3; */
}
.pv-ppt-progress-current-bg {
  position: absolute !important;
  background-color: #03a9f4 !important;
  left: 0;
}
.pv-ppt-progress-target {
  display: none;
}
</style>

<style lang="scss" scoped>
.carCount {
  position: absolute;
  display: inline-block;
  width: 13px;
  height: 13px;
  background: #f00;
  border-radius: 50%;
  z-index: 99;
  right: -10px;
  top: -6px;
  color: #ffffff;
  font-size: 6px;
  text-align: center;
  line-height: 13px;
}
.downapp {
  position: relative;
}
.downapp::after {
  content: '扫描下载APP';
  position: absolute;
  bottom: 0;
  left: -18px;
  display: inline-block;
  width: 80px;
}
.signPoint {
  margin-top: 15px;
  font-size: 12px;
  color: #1dcad3;
}
.otherImg:nth-child(5) {
  margin-left: 40px !important;
}
.otherImg:nth-child(7) {
  margin-right: 45px !important;
}
.myRead {
  width: 133px;
  height: 38px;
  opacity: 1;
  background: #1dcad3;
  border-radius: 19px;
  color: #ffffff;
  margin-top: 20px;
}
.signReamk {
  font-size: 13px;
  color: #b0b0b0;
  margin-top: 8px;
}
.signBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 320px;
  height: 410px;
  border-radius: 12px;
  background: #fff;
  .signState {
    font-size: 24px;
    margin-top: 37px;
    color: #363636;
  }
}
.useroper {
  position: relative;
  min-width: 226px;
}
.oper {
  display: none;
  width: 100%;
  height: 106px;
  background: #fff;
  position: absolute;
  top: 43px;
  left: 0;
  z-index: 999;
  border: 1px solid #efefef;
  .logout {
    font-size: 14px;
    color: #484343;
    height: 54px;
    width: 100%;
    &:hover {
      background: #edeff4 !important;
    }
  }
}
.useroper:hover {
  .oper {
    display: block !important;
  }
}
footer {
  width: 100%;
  height: 260px;
  background: #3b404d;
  position: relative;
  overflow: hidden;
  .fenge {
    width: 100%;
    border-top: 1px solid #585858;
    height: 41px;
    color: #7c7c7c;
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    line-height: 41px;
    a{
      color: #7c7c7c;
      font-size: 12px;
    }
    a:visited{
      color: #7c7c7c;
      font-size: 12px;
    }
  }
  .footerTopContent {
    margin-top: 90px;
    height: 110px;
    width: 100%;
    .footerTopContentRight {
      .dl {
        width: 130px;
        text-align: center;
        float: left;
        // margin-left: 60px;
        &:hover {
          img {
            transform: scale(2);
          }
        }
        .dd {
          margin-top: 19px;
          color: #ffffff;
          font-size: 13px;
          line-height: 18px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
    .footerTopContentLeft {
      .opacityLogo {
        width: 152px;
        height: 108px;
        float: left;
      }
      .jumpbottom {
        margin-top: 40px;
        li {
          float: left;
          border-right: 1px solid #a5a5a5;
          padding-right: 17px;
          margin-right: 18px;
          font-size: 13px;
          color: #a5a5a5;
          cursor: pointer;
          &:last-child {
            border: none;
          }
        }
      }
      .work {
        float: left;
        margin-left: 50px;
        .worktop {
          .worktopleft {
            height: 50px;
            align-items: start;
            .worktoplefttexttop {
              font-size: 14px;
              color: #c6c6c6;
            }
            .worktoplefttextbot {
              font-size: 15px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
nav {
  height: 106px;
  .rightContent {
    height: 40px;
    align-items: end;
    cursor: pointer;
    .calcIMg {
      .calcIMgShow {
        display: none;
      }
      transition: all 0.3s;
      &:hover {
        .calcIMgShow {
          display: block;
          width: 120px;
          height: 135px;
          position: absolute;
          left: -10px;
          top: 60px;
          z-index: 99;
          background: #fff;
          border-radius: 5px;
          border: 1px solid #ededed;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 12px;
          justify-content: space-around;
          .calcIMgShowImg {
            width: 95px;
            height: 95px;
            margin-bottom: 10px;
          }
        }
        // img{
        // 	transform: scale(2);
        // }
      }
    }
    .signBtn {
      width: 42px;
      height: 42px;
      position: relative;
      float: left;
      margin-left: 40px;
      img {
        width: 100%;
        height: 100%;
      }
      span {
        position: absolute;
        font-size: 13px;
        color: #777777;
        bottom: -25px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    .searchContent {
      width: 243px;
      height: 38px;
      border: 1px solid #b5b5b5;
      border-radius: 3px;
      overflow: hidden;
      float: left;
      input[type='text'] {
        height: 38px;
        width: 190px;
        padding-left: 18px;
        font-size: 15px;
        color: #a5a5a5;
      }
      .searchContent_btn {
        width: 53px;
        height: 39px;
        background: #47d7e3;
        flex: 1;
        cursor: pointer;
        img {
          width: 21px;
          height: 21px;
        }
      }
    }
  }
  .centerContent {
    .navList {
      cursor: pointer;
      float: left;
      margin-right: 50px;
      color: #0b0b0b;
      // font-weight: 600;
      font-size: 18px;
    }
  }
  .leftContent {
    height: 100%;
    .logoWeb {
      width: 148px;
      height: 40px;
    }
    .welcomeTo {
      margin-left: 25px;
      padding-left: 25px;
      border-left: 1px solid #707070;
      color: #484343;
      font-size: 18px;
    }
  }
}
.headerIcon {
  width: 18px;
  height: 18px;
  margin-right: 12px;
}
header {
  width: 100%;
  height: 43px;
  background: #edeff4;
  position: sticky;
  z-index: 99;
  top: 0;
  .headerCenter {
    height: 100%;
    ul {
      height: 100%;
      cursor: pointer;
    }
    li {
      // width: 113px;
      float: left;
      background: #edeff4;
      height: 100%;
    }
    .goodsBtn {
      width: 113px;
      background: #f2f5fa;
      height: 100%;
      text-align: center;
    }
    .welcomeText {
      cursor: pointer;
      font-size: 14px;
    }
  }
}
</style>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.router_view {
  min-height: calc(100vh - 410px);
  background-color: rgb(247, 248, 250);
}
</style>
