export default [
    {
        path: '/goodslist/car',
        name: 'goodsCar',
        component: () => import('@/views/layout/goodsChildren/car.vue'),
        meta: {
            title: '购物车'
        },
    },
    {
        path: '/goodslist/confirmorder',
        name: 'confirmOrder',
        component: () => import('@/views/layout/goodsChildren/confirmOrder.vue'),
        meta: {
            title: '购物车'
        },
    },
    {
        path: '/goodslist/ordersuccess',
        name: 'orderSuccess',
        component: () => import('@/views/layout/goodsChildren/orderSuccess.vue'),
        meta: {
            title: '购物车'
        },
    },
    {
        path: '/goodslist/paysuccess',
        name: 'paySuccess',
        component: () => import('@/views/layout/goodsChildren/paySuccess.vue'),
        meta: {
            title: '购物车'
        },
    }
]