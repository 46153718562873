export default [
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/layout/doLogin/register'),
        meta: {
            title: '注册'
        }
    },
    {
        path: '/cominfor',
        name: 'completeInformation',
        component: () => import('@/views/layout/doLogin/completeInformation'),
        meta: {
            title: '完善信息'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/layout/doLogin/login'),
        meta: {
            title: '登录'
        }
    }
]