export default [
    {
        path: '/shop',
        name: 'shopHome',
        component: () => import('@/views/layout/shop/index'),
        meta: {
            title: '商城'
        }
    },
    {
        path: '/shoplist',
        name: 'shoplist',
        component: () => import('@/views/layout/shop/shoplist'),
        meta: {
            title: '商品列表'
        }
    },
    {
        path: '/shopdetail',
        name: 'shopDetail',
        component: () => import('@/views/layout/shop/shopDetail'),
        meta: {
            title: '商品详情'
        }
    },
]