import teacherList from '@/router/webMainChildren'
import goodsChildren from '@/router/webMainChildren/goodsChildren'
export default [
  {
    path: '/More',
    name: 'webMainEntry',
    component: () => import('@/views/layout/webMain/index.vue'),
    meta: {
      title: '注册'
    },
  },
  {
    path: '/allcourse',
    name: 'allCourse',
    component: () => import('@/views/layout/webMain/allCourse.vue'),
    meta: {
      title: '全部课程'
    },
  },
  {
    path: '/teacherdetail',
    name: 'thacerDetail',
    component: () => import('@/views/layout/webMain/thacerDetail.vue'),
    meta: {
      title: '讲师详情'
    },
  },
  {
    path: '/goodslist',
    name: 'goodsList',
    component: () => import('@/views/layout/webMain/goodsList.vue'),
    meta: {
      title: '购物车'
    },
    redirect: '/goodslist/car',
    children: [
      ...goodsChildren
    ]
  },
  ...teacherList
]