<template>
    <div class="addressSelect flex spacebetween ">
        <el-select style="width:176px;" @change="handleChangeProvince" :disabled="selectTurn[0]" v-model="addressbind[0]" placeholder="请选择省份">
            <el-option
            v-for="(im,ix) in province"
            :key="ix"
            :label="im.full_name"
            :value="im.id"
            >
            </el-option>
        </el-select>
        <el-select style="width:176px;margin-left:20;" @change="handleChangeCities" :disabled="selectTurn[1]" v-model="addressbind[1]" placeholder="请选择城市">
            <el-option
            v-for="(im,ix) in cities"
            :key="ix"
            :label="im.full_name"
            :value="im.id">
            </el-option>
        </el-select>
        <el-select style="width:176px;margin-left:20;" @change="handleChangeCounty" :disabled="selectTurn[2]" v-model="addressbind[2]" placeholder="请选择地区">
            <el-option
            v-for="(im,ix) in county"
            :key="ix"
            :label="im.full_name"
            :value="im.id">
            </el-option>
        </el-select>
    </div>
</template>
<script>
import { get_address } from '@/utils/Api/commonList'
export default {
    name:'addressSelect',
    props:{
        /* 省字段 */
        provice:{
            type: String,
            default: ''
        },
        /* 绑定字段 */
        addressbinds:{
            type: Array,
            default: () => []
        }
    },
    data(){
        return{
            addressbind:[],
            selectTurn:[false,true,true],
            province:[],
            cities:[],
            county:[]
        }
    },
    mounted(){
        this.initial()
    },
    methods:{
        /* 获取省 */
        async initial(){
            this.province = await get_address({type:1}) ? await get_address({type:1}) : this.$message.error('省份获取失败')
            console.log(this.province)
            // return
            if(this.addressbinds[1] && this.addressbinds[3]){
                this.addressbind[0] = this.addressbinds[0]
                this.handleChangeProvince(this.addressbinds[0])
                this.addressbinds[0] = null
            }
        },
        /* 获取市  选中省触发事件 */
        async handleChangeProvince(value){
            /* 触发置空二三级绑定值 以及 二三级开关 */
            this.cities = []
            this.selectTurn[1] = true
            this.addressbind[1] = ''
            this.selectTurn[2] = true
            this.addressbind[2] = ''
            this.cities = await get_address({type:2,parent_id: value}) ? await get_address({type:2,parent_id: value}) : this.$message.error('城市获取失败')
            this.selectTurn[1] = false
            if(this.addressbinds[2] && this.addressbinds[3]){
                this.addressbind[1] = this.addressbinds[1]
                this.handleChangeCities(this.addressbinds[1])
                this.addressbinds[1] = null
            }
        },
        /* 获取区  选中市触发事件 */
        async handleChangeCities(value){
            /* 触发置空三级绑定值 以及 三级开关 */
            this.county = []
            this.selectTurn[2] = true
            this.addressbind[2] = ''
            this.county = await get_address({type:3,parent_id: value}) ? await get_address({type:3,parent_id: value}) : this.$message.error('地区获取失败')
            this.selectTurn[2] = false
            if(this.addressbinds[2] && this.addressbinds[3]){
                this.addressbind[2] = this.addressbinds[2]
                this.addressbinds[2] = null
            }
        },
        /* 选中区触发事件 */
        async handleChangeCounty(value){
        }
    },
    watch:{
        addressbind(){
            this.$emit('proviceSE', this.addressbind, this.provice)
        },
        addressbinds(){
            this.initial()
        }
    }
}
</script>
<style lang="scss" scoped>
.addressSelect{
    width: 100%;
}
</style>