
// 基本配置项  与公共方法
// exports.baseUrl = 'http://xinyiyun.test01.qcw100.com/api/'
exports.baseUrl = 'http://nmep.com.cn/api.php/api/'
// let imgUrl = 'http://xinyiyun.test01.qcw100.com'
let imgUrl = 'http://nmep.com.cn'
exports.imgUrl = imgUrl
exports.regexImg = function (value) {
  return /^(http|https):/.test(value) || /^(@)/.test(value) ? value : imgUrl + value
}
exports.onPreview = function (value) {
  return uni.previewImage({
    current: 0,
    urls: [/^(http|https):/.test(value) || /^(@)/.test(value) ? value : regexImg + value],
  });
}
exports.formatFloat = function (arg1, arg2, operation) {
  var r1, r2, m;
  try { r1 = arg1.toString().split(".")[1].length; } catch (e) { r1 = 0; }
  try { r2 = arg2.toString().split(".")[1].length; } catch (e) { r2 = 0; }
  m = Math.pow(10, Math.max(r1, r2));
  switch (operation) {
    case 1:
      return Number(((arg1 * m + arg2 * m) / m).toFixed(2));
    case 2:
      return Number(((arg1 * m - arg2 * m) / m).toFixed(2));
    case 3:
      return Number((arg1 * m * arg2 * m) / (m * m)).toFixed(2);
    case 4:
      return Number(((arg1 * m) / arg2) * m).toFixed(2);
    default:
      break;
  }
}