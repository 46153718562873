import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
// import play from './modules/play'
import navlist from '@/store/modules/navlist'
import common from '@/store/modules/common'
import meeting from '@/store/modules/meeting'

import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    navlist,
    common,
    meeting
    // play
  },
  getters
})
