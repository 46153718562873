<template>
    <div class="my_addAddress">
        <div class="title fontW">
            收货地址
        </div>
        <table>
            <tr>
                <td style="text-align:right;width:100px;padding-right:25px;">姓名</td>
                <td style="text-align:left;">
                    <el-input style="width:300px;" v-model="add_address.consignee_name" placeholder=""></el-input>
                </td>
            </tr>
            <tr>
                <td style="text-align:right;width:100px;padding-right:25px;">联系电话</td>
                <td style="text-align:left;">
                    <el-input style="width:300px;" v-model="add_address.consignee_phone" placeholder=""></el-input>
                </td>
            </tr>
            <tr>
                <td style="text-align:right;width:100px;padding-right:25px;">所在地区</td>
                <td style="text-align:left;">
                    <address-select :addressbinds="childrenData()" @proviceSE="proviceSE" ></address-select>
                </td>
            </tr>
            <tr>
                <td style="text-align:right;width:100px;padding-right:25px;">详细地址</td>
                <td style="text-align:left;">
                    <el-input style="width:300px;" v-model="add_address.address" placeholder=""></el-input>
                </td>
            </tr>
            <tr>
                <td style="text-align:right;width:100px;padding-right:25px;"></td>
                <td style="text-align:left;">
                    <div class="flex alignCenter ">
                        <div class="defaultOut flex alignCenter spacearound cursorP" @click="add_address.is_default = add_address.is_default+'' == '1' ? '2' : '1'">
                            <div class="defaultIn" v-show="add_address.is_default+'' == '1'"></div>
                        </div>
                        <span class="cursorP" @click="add_address.is_default = add_address.is_default+'' == '1' ? '2' : '1'">设为默认</span>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
import addressSelect from '@/components/addressSelect'
export default {
    name:'my_addAddress',
    data(){
        return{
            add_address:{
                is_default: false
            }
        }
    },
    components:{
        addressSelect
    },
    methods:{
        childrenData(){
            return []
        },
        proviceSE(val){
            console.log(val)
            this.add_address.provice = val[0]
            this.add_address.city = val[1]
            this.add_address.area = val[2]
        }
    }
}
</script>
<style lang="scss" scoped>
.defaultOut{
    width: 17px;
    height: 17px;
    border-radius: 50px;
    border: 1px solid #47D7E3;
    margin-right: 12px;
}
.defaultIn{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #47D7E3;
}
.my_addAddress{
    td{
        height: 50px;
    }
    .title{
        font-size: 20px;
        color: #212223;
        margin-top: 32px;
        margin-bottom: 24px;
    }
}
</style>