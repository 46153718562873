export function setToken(token, key){
    let keys = key ? key : 'token'
    return localStorage.setItem(keys, JSON.stringify(token))
}

export function getToken(key){
    let keys = key ? key : 'token'
    return localStorage.getItem(keys)
}

export function removeToken(key){
    let keys = key ? key : 'token'
    return localStorage.removeItem(keys)
}

export function isMobile() {
  let mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return mobile!= null
}