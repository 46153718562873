import router from './router'
import store from './store'
import { Message, MessageBox } from 'element-ui'
import NProgress from 'nprogress'
import { get_user_unique, pc_wxlogin_openid, istoinfo } from '@/utils/Api/commonList'
import 'nprogress/nprogress.css'
import { getToken, setToken } from '@/utils/auth'


NProgress.configure({ showSpinner: false })



const whiteList = ['/']
let breadList = []
let breadListTitle = []
let intercept = ['livePlay', 'noDemand']            // 课程权限
let whiteBread = ['全部课程', '学习计划', '首页', '个人中心', '商城']

router.beforeEach((to, from, next) => {
  // console.log(to, from)
  if (from.meta.title != '首页' && from.meta.title && breadListTitle[1] != from.meta.title && !whiteBread.includes(from.meta.title)) {
    breadList = [from, to]
    breadListTitle = [from.meta.title, to.meta.title]
  } else {
    breadList = [to]
  }
  store.commit('common/SET_NAVLIST', breadList)

  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '新医云'
  }
  NProgress.start()                       // 页面载入进度条  start 方法显示
  if (getToken()) {

    /* 获取以及设置用户信息 */
    if (!store.getters.token) {
      store.dispatch('user/set_token', JSON.parse(getToken()))
    }
    if (!store.getters.personal.id) {
      store.dispatch('user/getUserInfor')
    }
    if (store.getters.addressList.length < 1) {
      store.dispatch('user/address')
    }
    store.dispatch('user/myInfor')
    // 微信登录
    if (to.query.code && to.query.logtype) {
      pc_wxlogin_openid({
        wx_code: to.query.code
      }).then(res => {
        // console.log(res)
        store.commit('common/wxInfo', res)
        get_user_unique({
          type: 3,
          openid: res.openid,
          user_type: to.query.logtype,
        }).then(res => {
          // console.log(res)
          if (res.type == 0) {
            next(`/bindMobile?code=${to.query.code}`)
            return
          }
          store.dispatch('user/set_token', res.token)
          next(`/`)
          Message.success('登录成功！')
        })
      })
      return
    }

    if (store.getters.token && !store.getters.goodsCound) {
      store.dispatch('common/getCount')
    }


    // if(store.getters.token && to.path == '/login'){
    //     next('/')
    // }


    /* 课程权限 */
    if (intercept.includes(to.name)) {
      istoinfo({ course_id: from.query.id }).then(res => {
        if (res.status == 11037) {
          MessageBox.confirm('请登录后继续观看课程，是否去登录？', {
            cancelButtonText: '算了吧',
            confirmButtonText: '去登陆',
            type: 'warning'
          }).then(() => {
            router.push({ path: '/login', query: { redirect: encodeURI(router.app._route.fullPath) } })
          }).catch(() => {
            next(from.fullPath)
          })
          return
        }
        if (res.status == 11038) {
          MessageBox.confirm('请完善个人信息后继续观看课程，是否去完善？', {
            cancelButtonText: '算了吧',
            confirmButtonText: '去完善',
            type: 'warning'
          }).then(() => {
            router.push({ path: '/personal/myinfo', query: { redirect: encodeURI(router.app._route.fullPath) } })
          }).catch(() => {
            next(from.fullPath)
          })
          return
        }
      })
    }
    next()
    /* if(to.path == '/login'){
        next({path:'/'})
        NProgress.done()
    }else{

    } */
  } else {
    next()
    return
    if (whiteList.includes(to.path)) {
      next()
    } else {
      // next(`/?redirect=${to.fullPath}`)               // 重定向到登录页面 redirect的参数为即将前往的页面
      NProgress.done()
    }
  }





})

router.afterEach(() => {
  NProgress.done()                            // 页面载入进度条  done 方法关闭
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  })
})