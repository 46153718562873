import * as api from './http'

/* 签到 */
export function sign(params){
    return api.postApi('My/sign', params)
}

/* 获取用户信息 */
export function get_user(params){
    return api.postApi('My/get_user', params)
}

/* 修改个人信息 */
export function edit_user(params){
    return api.postApi('My/edit_user', params)
}

/* 申请成为讲师 */
export function lecturer_apply(params){
    return api.postApi('My/lecturer_apply', params)
}

/* 派发学习任务(整个部门) */
export function branch_hand_task(params){
    return api.postApi('Organ/branch_hand_task', params)
}

/* 机构部门列表 */
export function organ_offices(params){
    return api.postApi('Organ/organ_offices', params)
}

/* 修改个人信息(讲师) */
export function edit_user_lecturer(params){
    return api.postApi('My/edit_user_lecturer', params)
}

/* 修改个人信息(机构) */
export function OrgEditUser(params){
    return api.postApi('Organ/edit_user', params)
}

/* 邀请记录(用户、讲师) */
export function my_team(params){
    return api.postApi('My/my_team', params)
}

/* 我的课程（讲师） */
export function my_course(params){
    return api.postApi('Organ/my_course', params)
}

/* 用户加入机构 */
export function join_organ(params){
    return api.postApi('Organ/join_organ', params)
}

/* 猜你喜欢 */
export function MyCourse_like(params){
    return api.postApi('My/course_like', params)
}

/* 猜你喜欢（未登录） */
export function course_like_NoToken(params){
    return api.postApi('Home/course_like', params)
}

/* 我的学习计划 */
export function MyMy_plan(params){
    return api.postApi('My/my_plan', params)
}

/* 积分明细 */
export function point_log(params){
    return api.postApi('Organ/point_log', params)
}

/* 消息列表 */
export function information(params){
    return api.postApi('Course/information', params)
}

/* 读消息 */
export function informationRead(params){
    return api.postApi('Course/read', params)
}

/* 推荐讲师 */
export function recommend_lecturer(params){
    return api.postApi('Organ/recommend_lecturer', params)
}

/* 修改密码 */
export function set_pwd(params){
    return api.postApi('Organ/set_pwd', params)
}

/* 学习记录 */
export function my_look_log(params){
    return api.postApi('Course/my_look_log', params)
}

/* 排行榜 */
export function rank_list(params){
    return api.postApi('Organ/rank_list', params)
}

/* 帮助中心 */
export function help_list(params){
    return api.postApi('Organ/help_list', params)
}

/* 添加意见反馈 */
export function add_feedback(params){
    return api.postApi('Organ/add_feedback', params)
}

/* 客服电话 */
export function common_info(params){
    return api.postApi('Home/common_info', params)
}

/* 数据统计 */
export function census(params){
    return api.postApi('Organ/census', params)
}

/* 数据统计—用户地域统计 */
export function area_census(params){
    return api.postApi('Organ/area_census', params)
}

/* 数据统计—用户地域统计 */
export function course_census(params){
    return api.postApi('Organ/course_census', params)
}

/* 数据统计--课程详情统计 */
export function course_info_census(params){
    return api.postApi('Organ/course_info_census', params)
}

/* 申请助力培优 */
export function talents_apply(params){
    return api.postApi('Organ/talents_apply', params)
}

/* 申请助力培优 */
export function my_teamOrg(params){
    return api.postApi('Organ/my_team', params)
}

/* 数据统计（机构） */
export function organ_census(params){
    return api.postApi('Organ/organ_census', params)
}

/* 学习任务列表 */
export function study_task(params){
    return api.postApi('Organ/study_task', params)
}

/* 派发学习任务 */
export function hand_task(params){
    return api.postApi('Organ/hand_task', params)
}

/* 派发学习任务 */
export function plan_member(params){
    return api.postApi('Organ/plan_member', params)
}

/* 修改计划完成时间 */
export function new_plantime(params){
    return api.postApi('Organ/new_plantime', params)
}

/* 学习任务—成员管理—导出 */
export function export_plan_member(params){
    return api.postApi('Organ/export_plan_member', params)
}

/* 机构成员管理——学习记录 */
export function member_look_log(params){
    return api.postApi('Organ/member_look_log', params)
}

/* 我的学习计划 */
export function my_plan(params){
    return api.postApi('My/my_plan', params)
}

/* 我的学习计划-按照时间分类 */
export function myplan_bytime(params){
    return api.postApi('My/myplan_bytime', params)
}

/* 负责人组内成员 */
export function respon_people(params){
    return api.postApi('Organ/respon_people', params)
}