export default [
  {
    path: '/personal/myinfo',
    name: 'myInfo',
    component: () => import('@/views/layout/myCenter/personalRight/myInfo.vue'),
    meta: {
      title: '个人信息'
    },
  },
  {
    path: '/personal/myorginfo',
    name: 'myorginfo',
    component: () => import('@/views/layout/myCenter/personalRight/myOrgInfo.vue'),
    meta: {
      title: '我的机构'
    },
  },
  {
    path: '/personal/myorgstudy',
    name: 'myorgstudy',
    component: () => import('@/views/layout/myCenter/personalRight/myOrgStudy.vue'),
    meta: {
      title: '派发学习任务'
    },
  },
  {
    path: '/personal/medal',
    name: 'medal',
    component: () => import('@/views/layout/myCenter/personalRight/medalList.vue'),
    meta: {
      title: '勋章管理'
    },
  },
  {
    path: '/personal/integral',
    name: 'integral',
    component: () => import('@/views/layout/myCenter/personalRight/integral/index.vue'),
    meta: {
      title: '积分管理'
    },
  },
  {
    path: '/personal/message',
    name: 'message',
    component: () => import('@/views/layout/myCenter/personalRight/Message/index.vue'),
    meta: {
      title: '我的消息'
    },
  },
  {
    path: '/personal/recomteacher',
    name: 'recomteacher',
    component: () => import('@/views/layout/myCenter/personalRight/recomteacher.vue'),
    meta: {
      title: '推荐讲师'
    },
  },
  {
    path: '/personal/setpassword',
    name: 'setpassword',
    component: () => import('@/views/layout/myCenter/personalRight/setPassword.vue'),
    meta: {
      title: '重置密码'
    },
  },
  {
    path: '/personal/invitedrecord',
    name: 'invitedRecord',
    component: () => import('@/views/layout/myCenter/personalRight/invitedRecord.vue'),
    meta: {
      title: '邀请记录'
    },
  },
  {
    path: '/personal/studyrecord',
    name: 'studyRecord',
    component: () => import('@/views/layout/myCenter/personalRight/studyRecord.vue'),
    meta: {
      title: '学习记录'
    },
  },
  {
    path: '/personal/becometeacher',
    name: 'becomeTeacher',
    component: () => import('@/views/layout/myCenter/personalRight/becomeTeacher.vue'),
    meta: {
      title: '申请成为讲师'
    },
  },
  {
    path: '/personal/ranklist',
    name: 'rankList',
    component: () => import('@/views/layout/myCenter/personalRight/rankList.vue'),
    meta: {
      title: '排行榜'
    },
  },
  {
    path: '/personal/aboutours',
    name: 'aboutOurs',
    component: () => import('@/views/layout/myCenter/personalRight/aboutOurs.vue'),
    meta: {
      title: '关于我们'
    },
  },
  {
    path: '/personal/helpercenter',
    name: 'helperCenter',
    component: () => import('@/views/layout/myCenter/personalRight/helperCenter.vue'),
    meta: {
      title: '帮助中心'
    },
  },
  {
    path: '/personal/servicecenter',
    name: 'serviceCenter',
    component: () => import('@/views/layout/myCenter/personalRight/serviceCenter.vue'),
    meta: {
      title: '客服中心'
    },
  },
  {
    path: '/personal/mycourse',
    name: 'myCourse',
    component: () => import('@/views/layout/myCenter/personalRight/myCourse.vue'),
    meta: {
      title: '我的课程'
    },
  },
  {
    path: '/personal/datastatis',
    name: 'dataStatis',
    component: () => import('@/views/layout/myCenter/personalRight/dataStatis.vue'),
    meta: {
      title: '数据统计'
    },
  },
  {
    path: '/personal/talentsapply',
    name: 'talentsApply',
    component: () => import('@/views/layout/myCenter/personalRight/talentsApply.vue'),
    meta: {
      title: '助力培优'
    },
  },
  {
    path: '/personal/member',
    name: 'member',
    component: () => import('@/views/layout/myCenter/personalRight/member.vue'),
    meta: {
      title: '成员管理'
    },
  },
  {
    path: '/personal/orgdatastatis',
    name: 'orgDatastatis',
    component: () => import('@/views/layout/myCenter/personalRight/orgDatastatis.vue'),
    meta: {
      title: '数据统计'
    },
  },
  {
    path: '/personal/studytask',
    name: 'studyTask',
    component: () => import('@/views/layout/myCenter/personalRight/studyTask/index.vue'),
    meta: {
      title: '学习任务'
    },
  },
  {
    path: '/personal/orgchart',
    name: 'orgChart',
    component: () => import('@/views/layout/myCenter/personalRight/orgChart.vue'),
    meta: {
      title: '组织架构'
    },
  },
  {
    path: '/personal/orgchartNew',
    name: 'orgChart',
    component: () => import('@/views/layout/myCenter/personalRight/orgChartNew.vue'),
    meta: {
      title: '组织架构'
    },
  },
  {
    path: '/personal/myaddress',
    name: 'myAddress',
    component: () => import('@/views/layout/myCenter/personalRight/myAddress.vue'),
    meta: {
      title: '地址管理'
    },
  },
  {
    path: '/personal/orderlist',
    name: 'orderList',
    component: () => import('@/views/layout/myCenter/personalRight/Order/orderList.vue'),
    meta: {
      title: '我的订单'
    },
  },
  {
    path: '/personal/meetingOrder',
    name: 'meetingOrder',
    component: () => import('@/views/layout/myCenter/personalRight/meetingOrder/index.vue'),
    meta: {
      title: '会议订单'
    },
  },
  {
    path: '/personal/meetingOrderDetail',
    name: 'meetingOrderDetail',
    component: () => import('@/views/layout/myCenter/personalRight/meetingOrder/details.vue'),
    meta: {
      title: '会议订单'
    },
  },
  {
    path: '/personal/hotelDetails',
    name: 'hotelDetails',
    component: () => import('@/views/layout/myCenter/personalRight/meetingOrder/hotelDetails.vue'),
    meta: {
      title: '酒店订单'
    },
  },
]